@import "../assets/fonts/fonts.css";

body {
  margin: 0;
  font-family: "Neue Montreal", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  overflow-x: hidden;

  user-select: none;

  height: 100vh;
}
